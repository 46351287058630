<template>
  <div>
    Template Lesiur Tab Item
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

export default {
  name: 'TemplateLesiurTabItem',
  mixins: [gMixin, imageUrlMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    domesticItem: {
      type: Object,
      default: null,
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      destinationImages: 'GET_DESTINATION_IMAGES',
      isLanding: 'GET_IS_LANDING_PAGE',
      landingInfo: 'GET_LANDING_INFO',
      dealLocation: 'GET_DEAL_LOCATION',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      odyAgentCode: 'GET_SELECTED_ODYSSEY_AGENT_CODE',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
    }),
    fcAgentModeParam() {
      return (this.isFcAgentMarketerMode) ? '&fc-agent-mode' : '';
    },
    item() {
      const valueArray = this.items[1];
      // if (valueArray && valueArray[0].categoryId === 'Organize_tour_packages') console.log(valueArray, 111);
      // console.log(valueArray[0].dealTypeCode, valueArray[0].indexNumber, valueArray[0]);
      return (valueArray) ? valueArray[0] : null;
    },
    moreItems() {
      const valueArray = this.items[1];
      return (valueArray && valueArray.length > 1) ? valueArray.slice(1) : [];
    },
    priceRemark() {
      return (this.item) ? this.item?.priceRemarkTrans[this.lang] : '';
    },
    luggageWeight() {
      if (this.item && this.item?.packageType === 'F') {
        return this.item?.roundTripFlight ? this.item?.roundTripFlight.FlightDetail[0].FL_Max_Weight : 0;
      } else {
        return 0;
      }
    },
    handWeight() {
      if (this.item && this.item?.packageType === 'F') {
        return this.item?.roundTripFlight ? this.item?.roundTripFlight.FlightDetail[0].FL_Max_Weight_Hand : 0;
      } else {
        return 0;
      }
    },
    isSalesDeal() {
      if (!this.item) return false;
      return (this.domesticItem) ? false : this.item.isSalesDeal;
    },
    isWithTimer() {
      if (!this.item) return false;
      return this.item.withTimer;
    },
    itineraryProps() {
      return {
        destCode: this.item.destinationCode,
        itinerary: this.item.itinerary,
        landing: this.isLanding,
        hasMoreDeal: this.moreItems && this.moreItems.length > 0,
      };
    },
    odyAgentCodeParam() {
      return (this.isOdysseySite) ? `&odyAgentCode=${this.odyAgentCode}` : '';
    },
    isMobile() {
      return this.device === 'mobile';
    },
  },
  data() {
    return {
      mainProps: {
        // fluidGrow: true,
        // blank: true,
        // blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
      isSalesDealExpired: false,
      utmSourceQuery: this.$route.query.utm_source ? `&utm_source=${this.$route.query.utm_source}` : '',
    };
  },
  mounted() {
    if (this.dealLocation && this.dealLocation.categoryId === this.item.categoryId) {
      setTimeout(() => {
        document.querySelector('.st-content').scrollTo(0, this.dealLocation.location);
      }, 100);
    }
  },
  methods: {
    changeDateFormat(str) {
      return str.split('-').reverse().join('.');
    },
    getDestinationImage(dest) {
      const matchedItem = this.destinationImages.find((d) => d.code === dest);
      const url = (matchedItem) ? this.destinationImages.find((d) => d.code === dest).imageUrls[0] : '';
      return url;
    },
    calcNight(day1, day2) {
      const a = dayjs(day1, 'DD/MM/YY');
      const b = dayjs(day2, 'DD/MM/YY');
      return a.diff(b, 'day');
    },
    finishCountdown() {
      this.isSalesDealExpired = true;
    },
    recordLocation() {
      const location = this.$refs.position.offsetParent.offsetTop + this.$refs.position.offsetTop;
      this.$store.commit('SET_DEAL_LOCATION', { location, categoryId: this.item.categoryId });
    },
  },
};
</script>
